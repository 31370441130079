import {customElement, property} from "lit/decorators.js";
import {LoadAfterConfirmationEvents} from "../../page/elements/loadAfterConfirmation";
import {resolve} from "../../container";
import {Resolution} from "../../common/resolution";
import {EOP_ERRORS} from "../../common/utils/promises";
import {GoogleMapsFactory} from "./googleMapsFactory";
import {ManagingResources} from "../../common/lifetime";
import {UnLitElement} from "../../common/elements";
import {COLOR_PRIMARY, COLOR_WHITE} from "./colors";
import {GoogleMapsRunner} from "./runner";
import {EopCustomClusterRenderer} from "./clusterRenderer";
import type {EopGoogleMapsPoi} from "./poi";

@customElement("eop-google-map")
export class EopGoogleMap extends ManagingResources(UnLitElement) {

    private mapCenter: google.maps.LatLng | undefined;
    private map: google.maps.Map;
    private pois: EopGoogleMapsPoi[];
    private initialized: boolean;
    private googleMapsElement: HTMLElement | null;

    @property({attribute: "zoom", type: Number})
    public zoom: number = 10;
    @property({attribute: "center-by-pois", type: Boolean})
    private centerByPOIs: boolean = false;
    @property({attribute: "center-lat", type: Number})
    private centerLat: number;
    @property({attribute: "center-lng", type: Number})
    private centerLng: number;
    @property({attribute: "map-type"})
    public mapType: string = "roadmap";
    @property({attribute: "clustering", type: Boolean})
    public useClustering: boolean = false;


    public constructor(
        private loadAfterConfirmationEvents: LoadAfterConfirmationEvents = resolve(LoadAfterConfirmationEvents),
        private googleMapsRunner: GoogleMapsRunner = resolve(GoogleMapsRunner),
        private resolution: Resolution = resolve(Resolution),
        private googleMapsFactory: GoogleMapsFactory = resolve(GoogleMapsFactory)
    ) {
        super();

        this.initialized = false;
        this.pois = [];
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.loadAfterConfirmationEvents.onConfirmation("googlemaps", () => {
            return this.googleMapsRunner.run()
                .then(() => this.initMap())
                .catch(EOP_ERRORS);
        });
    }

    private initMap(): void {
        if (this.initialized) {
            return;
        }
        this.initialized = true;

        this.googleMapsElement = this.querySelector<HTMLElement>(".google-maps-element")!;
        const pois = Array.from(this.querySelectorAll<EopGoogleMapsPoi>("eop-google-maps-poi"));
        this.mapCenter = this.determineMapCenter(pois);

        this.map = this.googleMapsFactory.createMap(this.googleMapsElement, {
            center: this.mapCenter,
            zoom: this.zoom,
            mapTypeControl: true,
            scaleControl: true,
            mapTypeId: this.mapType,
            mapId: "DEMO_MAP_ID"
        });

        this.resolution.onWindowResize(() => {
            if (this.mapCenter) {
                this.map.setCenter(this.mapCenter);
            }
        }, this);
        pois.forEach(poi => this.addPoi(poi));

        this.map.addListener("click", () => {
            this.pois.forEach(p => p.close());
        });

        this.googleMapsElement.firstElementChild!.prepend(this.querySelector(".pois")!);

        if (this.useClustering) {
            this.googleMapsFactory.createMarkerClusterer(this.map, this.pois.flatMap(p => p.marker),
                new EopCustomClusterRenderer(this.getComputedColor(COLOR_WHITE), this.getComputedColor(COLOR_PRIMARY)));
        }
    }

    private determineMapCenter(pois: EopGoogleMapsPoi[]): google.maps.LatLng {
        if (this.centerByPOIs) {
            const mostNorth = Math.max(...pois.map(poi => poi.position().lat()));
            const mostSouth = Math.min(...pois.map(poi => poi.position().lat()));
            const mostEast = Math.max(...pois.map(poi => poi.position().lng()));
            const mostWest = Math.min(...pois.map(poi => poi.position().lng()));

            return this.googleMapsFactory.createLatLng((mostNorth + mostSouth) / 2, (mostEast + mostWest) / 2);
        } else {
            return this.googleMapsFactory.createLatLng(this.centerLat, this.centerLng);
        }
    }

    private addPoi(poi: EopGoogleMapsPoi): void {
        const marker = poi.renderOnMap(this.map);
        marker.addListener("click", () => {
            this.pois.filter(p => p.isOpen() && p !== poi)
                .forEach(p => p.close());
            poi.toggleOpen();
        });

        this.pois.push(poi);
    }

    private getComputedColor(propertyName: string): string {
        return getComputedStyle(this).getPropertyValue(propertyName);
    }
}